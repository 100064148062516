import { notify } from '@kyvg/vue3-notification';

export default class NotificationService {
  showErrorMessage(message) {
    var error = null;
    if (typeof message != 'undefined') {
      error = message.data.message;
      if (typeof message.data.errors != 'undefined') {
        var indexs = Object.keys(message.data.errors);
        indexs.forEach(function (value) {
          error = '\n' + message.data.errors[value].join();
        });
      }
    } else {
      error = 'Ops.. Algo deu errado tente novamente mais tarde!';
    }

    notify({
      type: 'error',
      text: error,
      duration: 4000,
    });
  }

  showSuccess(message) {
    notify({
      type: 'success',
      text: message,
      duration: 4000,
    });
  }

  showErrorMessageTexto(message) {
    notify({
      type: 'error',
      text: message,
      duration: 4000,
    });
  }
}
